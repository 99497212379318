import {
  WizardStepInputType,
  WizardStep as WizardStepModel,
  WizardStepType,
} from "../../models/wizard";
import { WizardProgress } from "./WizardProgress";
import { WizardStep, WizardStepRef } from "./WizardStep";
import { getStep, getStepIndex } from "../../utils";
import toast from "react-hot-toast";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useAuthStore } from "../../stores/auth";
import { auth } from "../../firebase";
import { Loading } from "../Loading";
import { useWizardStore } from "../../stores/wizard";
import { AuthState } from "../../constants/auth";
import { Back } from "../Back";

/* Each Wizard has the following step:
1. Welcome
2. Wizard Steps
3. Paywall
4. Complete */

interface Props {
  // Take name for localStorage key
  name: string;
  steps: Array<WizardStepModel>;
  wizardComplete: boolean;
  setWizardComplete: (wizardComplete: boolean) => void;
  children: any;
  step: WizardStepType;
  setStep: (step: WizardStepType) => void;
  stepResults: Record<string, string>;
  setStepResult: (stepType: string, result: string) => void;
  storeStep?: boolean;
  onBackPress?: VoidFunction
  hasPaid?: boolean
  showProgress?: boolean
}

export const Wizard = ({
  name,
  steps,
  wizardComplete,
  setWizardComplete,
  children,
  step,
  setStep,
  stepResults,
  setStepResult,
  storeStep,
  hasPaid = false,
  onBackPress = undefined,
  showProgress = true
}: Props) => {
  const { isSubscribed, authState } = useAuthStore();
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const { welcomeFilesUploading, welcomeReviewerFiles, email, setEmail } = useWizardStore();
  const [nextButtonTranslateY, setNextButtonTranslateY] = useState("32rem");

  const wizardStepRef = useRef<WizardStepRef>(null);

  useEffect(() => {
    // Calculate translateY based on the viewport height
    // You might need to adjust the calculation to fit your design
    const viewportHeight = window.innerHeight;
    const dynamicTranslateY = `${viewportHeight * 0.63}px`; // for example, 80% of the viewport height

    setNextButtonTranslateY(dynamicTranslateY);
  }, []); // Empty dependency array ensures this runs on mount

  // Checking if a payment has been made, if so we need to show some processing
  useEffect(() => {
    // We got a payment and a redirect
    if (window.location.search && step === WizardStepType.PAYWALL) {
      // Set that we are processing the payment
      setPaymentProcessing(true);

      // Get the search params
      const searchParams = new URLSearchParams(window.location.search);

      // Delete the params
      searchParams.delete("payment_intent");
      searchParams.delete("payment_intent_client_secret");
      searchParams.delete("redirect_status");

      // Create a new URL with the updated search parameters
      const newURL = `${window.location.pathname}?${searchParams.toString()}`;

      // Replace the current URL with the updated one
      window.history.replaceState({}, document.title, newURL);

      // Set a timeout (maybe a query to the API layer, to check if the payment is complete)
      setTimeout(() => {
        // setStep(ProfileStep.PROFILE);
        setWizardComplete(true);
        setPaymentProcessing(false);
      }, 3000);
    }
  }, [window.location]);

  useEffect(() => {
    if (storeStep) {
      console.log("STORING STEP", step)
      localStorage.setItem(`${name}:step`, step);
      if (step === "email") {
        console.log("STORING EMAIL", email)
        localStorage.setItem(`email`, email)
      } else {
        console.log("STORING STEP RESULTS", stepResults)
        localStorage.setItem(
          `${name}:stepResults`,
          JSON.stringify(stepResults)
        );
      }
    }
  }, [step, stepResults, email])

  useLayoutEffect(() => {
    if (step === WizardStepType.EMAIL && authState === AuthState.Authenticated && auth.currentUser && auth.currentUser.email) {
      const nextStep = getStep(step, 1, steps);
      if (nextStep) setStep(nextStep.step)
      else setWizardComplete(true)
    }
  }, [authState])

  const goToNextStep = async () => {
    let nextStep = getStep(step, 1, steps);
    if (step === WizardStepType.WELCOME) {
      setStep(nextStep.step);
    } else {
      let stepValue = stepResults[step];
      const currentStep = steps.find((wizardStep: WizardStepModel) => wizardStep.step === step);

      if (step === WizardStepType.EMAIL) {
        stepValue = wizardStepRef.current?.onNextPress() ?? ""
        console.log("WIZARD STEP VALUE FOR EMAIL", stepValue)
      }

      if (currentStep) {
        try {
          // If it has a validator
          if (currentStep.validator) {
            await currentStep.validator.validate(stepValue);
          }
          if (
            currentStep.step === WizardStepType.UPLOAD_PHOTO &&
            currentStep.type === WizardStepInputType.FILE &&
            (!welcomeReviewerFiles || welcomeReviewerFiles.length === 0)
          ) {
            toast.error('Please upload a screenshot of your profile!')
            return
          }
          // If the next step is "email" and the user is already authenticated
          if (nextStep && nextStep.step === WizardStepType.EMAIL && auth.currentUser && auth.currentUser.email) {
            // Get the step after the email step
            nextStep = getStep(nextStep.step, 1, steps);
          }
          if (nextStep) {
            setStep(nextStep.step);
          }
          // If there is no more next step, we are at the end
          if (!nextStep) {
            setWizardComplete(true);
          }
        } catch (error: any) {
          toast.error(error.message);
        }
      }
    }
  };

  // Check if it's Paywall and if it is, skip it if the user is subscribed
  useEffect(() => {
    if (auth.currentUser && isSubscribed && step == WizardStepType.PAYWALL) {
      goToNextStep();
    }
  }, [step]);

  const onBack = () => {
    const currentStep = getStepIndex(step, steps);

    if (currentStep === 0) {
      localStorage.removeItem(`${name}:step`);
      onBackPress?.();
      return;
    }

    let previousStep = getStep(step, -1, steps);

    // Check if the previous step is the email step and the user is already authenticated
    if (previousStep.step === "email" && auth.currentUser && auth.currentUser.email) {
      // Attempt to go back one more step if email is the previous step
      previousStep = getStep(previousStep.step, -1, steps);

      // If there's no previous step before email, handle it like going back from the 0th step
      if (!previousStep) {
        localStorage.removeItem(`${name}:step`);
        onBackPress?.();
        return;
      }
    }
    // Set the step to the previous step and store it in localStorage
    setStep(previousStep.step);
    localStorage.setItem(`${name}:step`, previousStep.step);
  }

  return (
    <div>
      {step !== WizardStepType.PAYWALL &&
        !paymentProcessing &&
        !wizardComplete &&
        !welcomeFilesUploading && (
          <div
            //  Next button
            className="fixed right-6 z-10" style={{ bottom: '7rem' }}
            // className="absolute right-0 mt-4 mr-4" 
            // style={{ transform: `translateY(${nextButtonTranslateY})` }}
            onClick={() => goToNextStep()}
          >
            <div className="mt-auto bg-brand-primary w-12 h-12 flex items-center justify-center rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2.5"
                className="w-8 h-8 stroke-white -mr-0.5 cursor-pointer"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </div>
          </div>
        )}
      {paymentProcessing ? (
        <Loading titles={["Payment Processing..."]} />
      ) : (
        <>
          {wizardComplete ? (
            <>{children}</>
          ) : (
            <>
              {step !== WizardStepType.PAYWALL ? (
                <div className="mx-auto max-w-xl">
                  <div className="mt-0">
                    {showProgress && <div className="mb-6">
                      <WizardProgress step={step} steps={steps} />
                    </div>}
                    {steps.map((wizardStep: WizardStepModel) => {
                      return (
                        <div key={wizardStep.step}>
                          {wizardStep.step === step ? (
                            <WizardStep
                              ref={wizardStepRef}
                              name={name}
                              key={wizardStep.label}
                              wizardStep={wizardStep}
                              goToNextStep={goToNextStep}
                              steps={steps}
                              step={step}
                              hasPaid={hasPaid}
                              email={email}
                              setEmail={!auth.currentUser?.email ? setEmail : undefined}
                              setStep={setStep}
                              stepResults={stepResults}
                              setStepResult={setStepResult}
                              onBackPress={onBackPress}
                            />
                          ) : null}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div>
                  {/* <WizardProgress step={step} steps={steps} /> */}
                  <>
                    {/* <div className="-ml-2 mb-2">
                      <Back containerClass="w-6 h-10" onClick={onBack} />
                    </div> */}
                    {/* <div className="h-4 w-full"></div> */}
                    {
                      steps?.find((wizardStep: WizardStepModel) => {
                        return wizardStep.step === step;
                      })?.content
                    }
                  </>
                </div>
              )}
            </>
          )}
        </>
      )
      }
    </div >
  );
};
