import React, { ReactNode } from 'react';

type Props = {
  title: string;
  onClick?: VoidFunction;
  containerClass?: string;
  titleClass?: string;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
}

export const OutlineButton = ({
  title,
  onClick,
  containerClass = '',
  titleClass = '',
  leftIcon,
  rightIcon,
}: Props) => (
  <button
    className={`flex items-center justify-center w-full bg-white text-brand-primary border-2 border-brand-primary h-12 rounded-lg font-semibold ${containerClass}`}
    onClick={onClick}
  >
    {leftIcon && <span className="mr-2">{leftIcon}</span>}
    <p className={`text-lg font-semibold ${titleClass}`}>{title}</p>
    {rightIcon && <span className="ml-2">{rightIcon}</span>}
  </button>
);