import React from 'react'
import { ProgressBar } from '../../components/ProgressBar'

type Props = {
  complete: boolean
  onLoadingComplete: VoidFunction
}


const loadingTitles = [
  "Analyzing your profile's first impressions...",
  "Diving deep into your photos...",
  "Evaluating photo quality and lighting...",
  "Scouting for social proof in pictures...",
  "Rearranging your photo lineup for maximum impact...",
  "Reviewing your bio",
  "Generating custom style suggestions…",
  "Summarizing our findings…",
  "Crafting your profile upgrade plan…",
  "Preparing to unveil the potential of your profile…"
];

export const ProfileReviewLoading = ({ complete, onLoadingComplete }: Props) => {
  return (
    <div className="flex flex-col items-center justify-center h-screen px-4">
      <div className="w-full max-w-lg text-center space-y-4 -mt-20 h-32">
        <ProgressBar totalTime={150} complete={complete} titles={loadingTitles} onCompleted={onLoadingComplete} />
      </div>
    </div>
  );
};