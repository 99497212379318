import React from "react";
import { FaChevronLeft, FaDownload, FaChevronRight } from "react-icons/fa";

type Props = {
  imageUrl: string;
  imageIndex: number;
  totalImages: number;
  onBackClick: () => void;
  onNextClick?: () => void;
  onPreviousClick?: () => void;
}

export const AIPhotoDetailView = ({
  imageUrl,
  imageIndex,
  totalImages,
  onBackClick,
  onNextClick,
  onPreviousClick,
}: Props) => {
  const downloadImage = async () => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = `downloaded-image-${imageIndex + 1}.jpg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Failed to download the image:", error);
    }
  };

  return (
    <div className="fixed inset-0 flex flex-col justify-between bg-black text-white z-50 overflow-hidden">
      <div className="absolute top-0 left-0 right-0 z-50 flex justify-between w-full p-4">
        <FaChevronLeft
          className="text-xl cursor-pointer"
          onClick={onBackClick}
        />
        <span className="text-sm">
          {imageIndex + 1}/{totalImages}
        </span>
      </div>

      <div className="flex justify-center items-center flex-1 mt-10 mb-10 relative">
        {/* Previous arrow */}
        {imageIndex > 0 && onPreviousClick && (
          <div className="absolute left-4 cursor-pointer p-2 bg-black/50 rounded-full">
            <FaChevronLeft
              className="text-2xl"
              onClick={onPreviousClick}
            />
          </div>
        )}

        <img
          src={imageUrl}
          alt="Displayed Image"
          className="max-w-full max-h-[90vh] rounded-md"
        />

        {/* Next arrow */}
        {imageIndex < totalImages - 1 && onNextClick && (
          <div className="absolute right-4 cursor-pointer p-2 bg-black/50 rounded-full">
            <FaChevronRight
              className="text-2xl"
              onClick={onNextClick}
            />
          </div>
        )}
      </div>

      <div className="absolute bottom-0 left-0 right-0 z-50 flex justify-center mb-4">
        <div className="flex flex-col items-center">
          <div className="bg-white text-black p-4 rounded-full flex justify-center">
            <FaDownload className="text-xl" onClick={downloadImage} />
          </div>
          <button className="text-white text-xs mt-2" onClick={downloadImage}>
            Download
          </button>
        </div>
      </div>
    </div>
  );
};