import { useEffect, useState } from 'react';
import { useProfileStore } from "../../stores/profile";
import { useWizardStore } from "../../stores/wizard";
import { logEvent } from "../../analytics";
import { ReviewedProfile } from "../../models/profile";
import { useUIStore } from "../../stores/ui";
import { SegmentedControlTabs } from "../../components/SegmentedControlTabs"
import { ProfileReviewUploadControl } from "./ProfileReviewUploadControl"
import { AIPhotoReview } from "../../pages/photo-review/AIPhotoReview";
import { ProfileReviewLoading } from "./ProfileReviewLoading"
import { ProfileReviewResult } from "./ProfileReviewResult"
import { YourMoveHeader } from '../YourMoveHeader';
import { ReviewType } from '../../models/wizard';
import { FamewallReviews } from '../../pages/FamewallReviews';
import { useProfileReviewData } from '../../pages/profile-review/useProfileReviewData';
import { useAuthStore } from '../../stores/auth';
import { auth } from '../../firebase';
import { addToMailingList, checkUserSubscription } from '../../queries';

enum ReviewStep {
  Upload = "Upload",
  Loading = "Loading",
  Review = "Review"
}

const segments = ['✍️ Profile', '🏄‍♂️ Photos'];

export const ProfileReview = () => {
  const {
    email,
    profileReviewerFiles,
    setProfileReviewerFiles,
    setProfileReviewerWizardComplete,
    reviewType,
    setReviewType,
  } = useWizardStore();
  const {
    setReviewedProfile,
    setHasPaidForProfileReview,
  } = useProfileStore();
  const { isSubscribed, setIsSubscribed } = useAuthStore()

  const { reviewedProfile } = useProfileStore()
  const { setError, } = useUIStore()

  useEffect(() => {
    const userEmail = auth.currentUser?.email ?? email;
    const checkSubscription = async () => {
      if (userEmail) {
        const response = await checkUserSubscription(userEmail);
        addToMailingList(email, 'profile_review');
        setIsSubscribed(response.data.isSubscribed);
      }
    };
    checkSubscription();
  }, [])

  const getInitialStep = () => {
    if (reviewedProfile) return ReviewStep.Review
    return ReviewStep.Upload
  }

  const [currentStep, setCurrentStep] = useState<ReviewStep>(getInitialStep)
  const [loadingReview, setLoadingReview] = useState(false)

  const { fetchReview } = useProfileReviewData()

  const fetchProfileReview = async () => {
    try {
      logEvent('review_ai_start', 'profile_review')
      setLoadingReview(true)

      const data = await fetchReview(email, profileReviewerFiles)
      setHasPaidForProfileReview(data.hasPaid || isSubscribed);
      setReviewedProfile(data as ReviewedProfile);
      setLoadingReview(false)
    }
    catch (error) {
      setError(
        "There was an error reviewing your profile, please try again later."
      );
      const params = (error as Error)?.message ? {
        reason: (error as Error)?.message
      } : undefined

      logEvent('review_ai_fail', 'profile_review', params, 'error')
    };
  }

  const handleSegmentChange = (index: number) => {
    const reviewType = index === 0 ? ReviewType.Profile : ReviewType.Photos;
    setReviewType(reviewType);
  };

  const onBack = () => {
    if (reviewType === ReviewType.Profile && currentStep !== ReviewStep.Upload) {
      setCurrentStep(ReviewStep.Upload)
    } else {
      setProfileReviewerWizardComplete(false)
    }
  }

  const onContinueClick = () => {
    if (reviewedProfile) {
      setCurrentStep(ReviewStep.Review)
    } else {
      setCurrentStep(ReviewStep.Loading)
      fetchProfileReview()
    }
  }

  const onLoadingComplete = () => {
    setCurrentStep(ReviewStep.Review)
  }

  return (
    <>
      <div className="mx-auto max-w-xl flex flex-col h-full bg">
        <YourMoveHeader containerClass='-mx-4 mb-2 mt-1' onBack={onBack} />
        <SegmentedControlTabs
          initialIndex={reviewType === ReviewType.Profile ? 0 : 1}
          segments={segments}
          onSegmentChange={handleSegmentChange}
        />
        {reviewType === ReviewType.Profile ? (
          <div className="flex-grow">
            {currentStep === ReviewStep.Upload ? (
              <ProfileReviewUploadControl
                files={profileReviewerFiles}
                setFiles={setProfileReviewerFiles}
                onContinueClick={onContinueClick}
              />
            ) : currentStep === ReviewStep.Loading ? (
              <ProfileReviewLoading complete={!loadingReview} onLoadingComplete={onLoadingComplete} />
            ) : currentStep === ReviewStep.Review ? (
              <ProfileReviewResult onReviewAnotherProfileClick={onBack} />
            ) : null
            }
          </div>
        ) : (
          <AIPhotoReview />
        )}
      </div>
      {reviewType === ReviewType.Profile && currentStep === ReviewStep.Review && (
        <FamewallReviews
          containerStyle='-mx-4 px-4 mt-4'
          title='See what our users say'
        />
      )}
    </>
  );
};
