type Props = {
  title: string
  description: string
  illustration: string | undefined
}

export const PremiumProductStep = ({ title, description, illustration }: Props) => (
  <div className="w-full mt-0 px-4 pt-1 flex flex-col items-center">
    <div className="text-center w-full max-w-sm">
      <p className="text-[20px] font-bold text-black mb-2">{title}</p>
      <p className="text-black mb-4 text-base leading-relaxed whitespace-normal">{description}</p>
    </div>
    <img
      src={illustration}
      alt="Premium content"
      className="h-60 pb-6"    
    />
  </div>
);