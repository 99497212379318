import { useState } from "react"
import { fetchAIPhotos } from "../../queries"
import { AIPhotoBatch } from './AIPhotos';
import { useAIPhotosStore } from '../../stores/aiphotos';

export const useAIPhotosResults = () => {
  const [loadingBatches, setLoadingBatches] = useState(false);
  const { aiPhotosEmail } = useAIPhotosStore()
  const [batches, setBatches] = useState<AIPhotoBatch[]>([])

  const fetchAIPhotoBatches = async () => {
    try {
      setLoadingBatches(true)
      const response = await fetchAIPhotos(aiPhotosEmail)
      const { data: { ai_photos = [] } } = response
      setBatches(ai_photos)
      return ai_photos
    } catch (err) {
      console.log(err)
      return undefined
    } finally {
      setLoadingBatches(false)
    }
  }

  return {
    loadingBatches,
    fetchAIPhotoBatches,
    batches
  }
}