export function getScrollableParent(element: HTMLElement): HTMLElement | null {
  const isScrollable = (el: HTMLElement) =>
    el.scrollHeight > el.clientHeight && ['auto', 'scroll'].includes(window.getComputedStyle(el).overflowY);

  let parent = element.parentElement;
  while (parent) {
    if (isScrollable(parent)) {
      return parent;
    }
    parent = parent.parentElement;
  }
  return null;
}