import * as Sentry from '@sentry/browser';
import { sendServerAnalyticsEvent } from '../queries';


type CustomWindow = Window & {
  gtag?: (
    command: string,
    target: string,
    ...args: any[]
  ) => void;
  rdt?: (command: string, eventName: string, eventParams: Record<string, any>) => void;
  fbq?: (command: string, eventName: string, eventParams?: Record<string, any>) => void;
}

export type EventParams = Record<string, string | number | boolean | undefined>;

export const logEvent = (
  eventName: string,
  product: string | undefined = undefined,
  eventParams: EventParams = {},
  eventCategory: string = 'funnel',
  storage: Storage = localStorage,
  win: CustomWindow = window
): void => {
  if (typeof win !== 'undefined') {
    const experimentValue = storage.getItem('experiment_group');
    const group = experimentValue !== null && experimentValue !== undefined ? parseInt(experimentValue, 10) : undefined;
    const experimentGroup = group === undefined ? undefined : group === 0 ? 'A' : 'B';
    const source = storage.getItem('utm_source')
    const campaign = storage.getItem('utm_campaign')

    const params: EventParams = {
      ...eventParams,
      'event_category': eventCategory,
      ...(product && { product: product }),
      ...(experimentGroup && { experiment_group: experimentGroup }),
      ...(source && { utm_source: source }),
      ...(campaign && { utm_campaign: campaign }),
    }

    console.log("LOGGING EVENT:: ", eventName, JSON.stringify(params))

    const logAnalyticsEvent = (platform: string, logFunction: Function) => {
      try {
        if (!win.location.hostname.includes('localhost')) {
          logFunction();
          console.log(`${platform} event logged successfully:`, eventName, JSON.stringify(params));
        }
      } catch (error) {
        Sentry.captureException(new Error(`Failed to log ${platform} event: ${error}`));
        console.error(`Failed to log ${platform} event:`, error);
      }
    };

    if (win.gtag) {
      logAnalyticsEvent('Google Analytics', () => win.gtag!('event', eventName, params));
    } else {
      console.warn('gtag is not available');
    }

    // Facebook Pixel tracking
    if (win.fbq) {
      logAnalyticsEvent('Facebook Pixel', () => win.fbq!('track', eventName, params));
    } else {
      console.warn('fbq is not available');
    }

    if (eventCategory === 'payment' && source === 'reddit') {
      const redditParams: EventParams = {}
      redditParams.customEventName = eventName
      redditParams.itemCount = 1
      redditParams.value = params.amount
      redditParams.currency = 'USD'

      console.log("LOGGING REDDIT EVENT:: ", redditParams.customEventName, JSON.stringify(redditParams))

      if (win.rdt) {
        logAnalyticsEvent('Reddit', () => win.rdt!('track', 'Custom', redditParams));
      } else {
        console.warn('rdt is not available');
      }
    }
  }
};

interface ServerEventParams extends EventParams {
  client_id?: string;
  user_id?: string;
}

// Helper function to get Google Analytics Client ID
export const getGoogleClientId = async (): Promise<string | undefined> => {
  return new Promise((resolve) => {
    const win = window as CustomWindow;
    if (typeof win !== 'undefined' && win.gtag) {
      const measurementId = import.meta.env.VITE_GA_MEASUREMENT_ID;
      win.gtag('get', measurementId, 'client_id', (clientId: string) => {
        console.log('Retrieved GA client ID:', clientId);
        resolve(clientId);
      });
    } else {
      console.warn('gtag not available for client ID retrieval');
      resolve(undefined);
    }
  });
};


// New server-side tracking function
export const logEventServerSide = async (
  eventName: string,
  email: string | undefined,
  product: string | undefined = undefined,
  eventParams: ServerEventParams = {},
  eventCategory: string = 'funnel',
  logMeta: boolean = true,
  logPosthog: boolean = false
): Promise<void> => {
  console.log("ANALYTICS:: logEventServerSide", eventName, email, product, eventParams, eventCategory)
  try {
    console.log("ANALYTICS:: Getting Google Client ID")
    const clientId = await getGoogleClientId();
    console.log("ANALYTICS:: Got Google Client ID", clientId)
    const source = localStorage.getItem('utm_source')
    const campaign = localStorage.getItem('utm_campaign')
    const params = {
      ...eventParams,
      client_id: clientId,
      ...(source && { utm_source: source }),
      ...(campaign && { utm_campaign: campaign }),
    };
    await sendServerAnalyticsEvent(
      eventName,
      email,
      product,
      params,
      eventCategory,
      logMeta,
      logPosthog
    );
    console.log("SERVER SIDE EVENT LOGGED:: ", eventName, JSON.stringify(params));
  } catch (error) {
    Sentry.captureException(new Error(`Failed to send server-side analytics: ${error}`));
    console.error(`Failed to send server-side analytics: ${error}`);
  }
};
