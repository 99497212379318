import { useProfileStore } from "../../stores/profile";
import { FullProfileReview } from "../../components/profile-reviewer/FullProfileReview"
import { FamewallReviews } from "../FamewallReviews";
import { useLogEvent } from "../../analytics";
import { OutlineButton } from '../../components/OutlineButton'

type Props = {
  onReviewAnotherProfileClick?: VoidFunction
}

export const ProfileReviewResultDemo = ({ onReviewAnotherProfileClick }: Props) => {
  const { demoReviewedProfile } = useProfileStore();

  useLogEvent('results', 'profile_review_demo');

  return (
    <>
      <div className="mx-auto max-w-xl">
        <div className="flex flex-col flex-1">
          <div className="mt-8">
            <div className="border-2 border-black rounded-2xl shadow-lg bg-white">
              <div className="flex flex-1 rounded-t-2xl bg-brand-alt-light bg-opacity-100">
                <div className="py-3 px-2 items-center flex-1">
                  <p className="font-semibold ml-2 text-sm">Current Rating</p>
                  <p className="font-bold ml-2 text-2xl">{`${demoReviewedProfile?.currentRating ?? 0}/10`}</p>
                </div>
                <div className="py-3 px-2 mr-2 items-center">
                  <p className="font-semibold ml-2 text-sm">Rating after improvement</p>
                  <p className="font-bold ml-2 text-2xl text-brand-primary">{`${demoReviewedProfile?.possibleRating ?? 0}/10`}</p>
                </div>
              </div>
              <div className="p-4 rounded-b-2xl">
                <FullProfileReview review={demoReviewedProfile} />
                <OutlineButton
                  containerClass="mt-4"
                  title="Review Another Profile"
                  onClick={onReviewAnotherProfileClick}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FamewallReviews
        containerStyle='-mx-4 px-4 mt-10'
        title='See what our users say'
      />
    </>
  );
};