import JSZip from 'jszip';

export const useDownloadImages = () => {
  const downloadAllImages = async (imageUrls: string[]) => {
    const zip = new JSZip();
    const imagePromises = imageUrls.map(async (imageUrl, index) => {
      try {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        zip.file(`image-${index + 1}.jpg`, blob);
      } catch (error) {
        console.error(`Failed to fetch image ${index + 1}:`, error);
      }
    });

    await Promise.all(imagePromises);

    const zipBlob = await zip.generateAsync({ type: "blob" });
    const zipUrl = URL.createObjectURL(zipBlob);
    const link = document.createElement("a");
    link.href = zipUrl;
    link.download = "images.zip";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(zipUrl);
  }

  return {
    downloadAllImages
  }
}