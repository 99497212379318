import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { supabase } from '../../supabase';
import { AIPhotosBatchDetail } from "./AIPhotosBatchDetail";

export const AIPhotosDemo = () => {
  const [loading, setLoading] = useState(true);
  const [folders, setFolders] = useState<string[]>([]);
  const [selectedFolder, setSelectedFolder] = useState<string | null>(null);
  const [imageUrls, setImageUrls] = useState<string[]>([]);

  useEffect(() => {
    const fetchDemoFolders = async () => {
      try {
        setLoading(true);
        
        const { data, error } = await supabase
          .storage
          .from('demo')
          .list('', {
            limit: 100,
            offset: 0,
            sortBy: { column: 'name', order: 'asc' }
          });

        if (error) throw error;

        const folderNames = data
          .filter(item => !item.metadata)
          .map(folder => folder.name);

        setFolders(folderNames);
      } catch (error) {
        console.error('Error fetching demo folders:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDemoFolders();
  }, []);

  const fetchImagesFromFolder = async (folderName: string) => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .storage
        .from('demo')
        .list(folderName);

      if (error) throw error;

      const urls = data.map(item => {
        const { data: { publicUrl } } = supabase.storage
          .from('demo')
          .getPublicUrl(`${folderName}/${item.name}`);
        return publicUrl;
      });

      const shuffledUrls = urls.sort(() => Math.random() - 0.5);

      console.log('Shuffled URLs:', shuffledUrls);
      setImageUrls(shuffledUrls);
    } catch (error) {
      console.error('Error fetching images from folder:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFolderClick = (folderName: string) => {
    setSelectedFolder(folderName);
    fetchImagesFromFolder(folderName);
  };

  const handleBackClick = () => {
    setSelectedFolder(null);
    setImageUrls([]);
  };

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className={`flex flex-col h-full max-w-lg mx-auto px-4 md:px-0 ${selectedFolder ? 'bg-gray-900' : ''}`}>
        {loading ? (
          <div>Loading...</div>
        ) : selectedFolder ? (
          <AIPhotosBatchDetail
            imageUrls={imageUrls}
            onBackClick={handleBackClick}
          />
        ) : (
          <div className="grid gap-4">
            {folders.map((folderName) => (
              <button
                key={folderName}
                className="flex items-center justify-center w-full bg-brand-primary text-white py-3 rounded-md font-semibold"
                onClick={() => handleFolderClick(folderName)}
              >
                {folderName}
              </button>
            ))}
          </div>
        )}
      </div>
    </>
  );
}; 