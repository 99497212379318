import { WizardStepType } from "../models/wizard";

// Checks local storage for initial step based on wizard name
export const checkLocalStorageForInitialStep = (name: string) => {
  const step = localStorage.getItem(`${name}:step`);
  return step ? step : name === 'profileWriter' ? WizardStepType.WELCOME : name === 'photoReview' ? WizardStepType.EMAIL : WizardStepType.GENDER;
};

export const getBooleanFromLocalStorage = (key: string): boolean => {
  const value = localStorage.getItem(key);
  return value === 'true';
};

// Checks local storage for initial step results based on wizard name
export const checkLocalStorageForKey = (name: string) => {
  const result = localStorage.getItem(`${name}`);
  return result ?? "";
};

export const checkLocalStorageForInitialStepResults = (name: string) => {
  const stepResults = localStorage.getItem(`${name}:stepResults`);
  const email = localStorage.getItem(`email`);
  if (stepResults) {
    const stepResultsObject = JSON.parse(stepResults)
    if (stepResultsObject?.email && !email) {
      localStorage.setItem(`email`, stepResultsObject?.email)
    }
    return stepResultsObject
  }
  return {}
};

export const checkLocalStorageForFiles = (name: string) => {
  const files = localStorage.getItem(`${name}Files`);
  if (files) {
    return JSON.parse(files);
  }

  const profileReviewerResults = checkLocalStorageForInitialStepResults(name);
  if (profileReviewerResults && profileReviewerResults.uploadPhoto) {
    return JSON.parse(profileReviewerResults.uploadPhoto);
  }

  return [];
}