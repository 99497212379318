import { create } from "zustand";
import { ProfileStep } from "../constants/profile";
import { ProfileResponse, Prompt, ReviewedProfile } from "../models/profile";
import { checkLocalStorageForKey } from "./utils";

interface UserStore {
  isCreator: boolean;
  setIsCreator: (isCreator: boolean) => void;
}

export const useUserStore = create<UserStore>((set) => ({
  isCreator: false,
  setIsCreator: (isCreator) =>
    set({ isCreator }),
}));
