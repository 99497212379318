import { Wizard } from "../../components/wizard/Wizard";
import { PROFILE_REVIEWER_WIZARD_STEPS } from "../../constants/wizard";
import { useWizardStore } from "../../stores/wizard";
import { Helmet } from 'react-helmet-async';
import { ProfileReviewLanding } from '../profile-review/ProfileReviewLanding'
import { useLogEvent } from "../../analytics";
import { ProfileReviewDemo } from './ProfileReviewDemo';

export const ProfileReviewerDemo = () => {
  const {
    demoStep,
    setDemoStep,
    demoStepResults,
    setDemoStepResults,
    demoWizardComplete,
    setDemoWizardComplete,
    demoReviewStarted,
    setDemoReviewStarted,
  } = useWizardStore();

  useLogEvent('start', 'profile_review')

  return (
    <div className="px-4">
      <Helmet>
        <meta name="title" content="Dating Profile Review" />
        <meta name="description" content="Get instant feedback on your dating profile. Trained by matchmakers, powered by AI." />
      </Helmet>
      {demoReviewStarted ? (
        <div>
          <Wizard
            name="demo"
            steps={PROFILE_REVIEWER_WIZARD_STEPS}
            wizardComplete={demoWizardComplete}
            setWizardComplete={setDemoWizardComplete}
            step={demoStep}
            setStep={setDemoStep}
            stepResults={demoStepResults}
            setStepResult={setDemoStepResults}
            onBackPress={() => setDemoReviewStarted(false)}
            storeStep={true}
            showProgress={false}
          >
            <ProfileReviewDemo />
          </Wizard>
        </div>
      ) : (
        <ProfileReviewLanding onProfileReviewClick={() => setDemoReviewStarted(true)} />
      )}
    </div>
  );
};
