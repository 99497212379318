import React, { useState, useRef, TouchEvent, MouseEvent } from "react";
import { ChatOnboardingStep } from './ChatOnboardingStep'
import { onboardingSteps } from './onboardingSteps'

type Props = {
  onClose?: VoidFunction
}

export const ChatOnboarding = ({ onClose = undefined }: Props) => {
  const [step, setStep] = useState(0);
  const startX = useRef<number | null>(null);
  const isDragging = useRef(false);

  const goToNext = () => {
    if (step < onboardingSteps.length - 1) setStep(prev => prev + 1);
  }

  const goToPrev = () => {
    if (step > 0) setStep(prev => prev - 1);
  }

  const handleDragStart = (clientX: number) => {
    startX.current = clientX;
    isDragging.current = true;
  };

  const handleDragEnd = (clientX: number) => {
    if (startX.current === null) return;

    const diff = startX.current - clientX;

    if (Math.abs(diff) > 50) { // Minimum swipe distance
      if (diff > 0) {
        goToNext();
      } else {
        goToPrev();
      }
    }

    startX.current = null;
    isDragging.current = false;
  };

  const handleTouchStart = (e: TouchEvent) => {
    handleDragStart(e.touches[0].clientX);
  };

  const handleTouchEnd = (e: TouchEvent) => {
    handleDragEnd(e.changedTouches[0].clientX);
  };

  const handleMouseDown = (e: MouseEvent) => {
    handleDragStart(e.clientX);
  };

  const handleMouseUp = (e: MouseEvent) => {
    if (isDragging.current) {
      handleDragEnd(e.clientX);
    }
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!isDragging.current) return;
    if (e.buttons === 0) {
      isDragging.current = false;
    }
  };

  return (
    <div className="relative w-full overflow-hidden">
      <div
        className="flex transition-transform duration-500 ease-in-out"
        style={{ transform: `translateX(-${step * 100}%)` }}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onMouseLeave={() => { isDragging.current = false; }}
      >
        {onboardingSteps.map((stepItem, index) => (
          <div key={index} className="w-full flex-shrink-0">
            <div className="max-w-[600px] mx-auto">
              <ChatOnboardingStep 
                illustration={stepItem.illustration} 
                title={stepItem.title} 
                description={stepItem.content} 
              />
            </div>
          </div>
        ))}
      </div>
      <div className="mt-4 mx-2">
        <button
          className="flex items-center justify-center w-full bg-brand-primary text-white py-3 rounded-xl font-semibold"
          onClick={() => {
            if (step < onboardingSteps.length - 1) goToNext()
            else onClose?.()
          }}>
          {step === onboardingSteps.length - 1 ? "Get started" : "Next"}
        </button>
      </div>
    </div>
  );
};