import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import { HelmetProvider } from 'react-helmet-async';
import { PostHogProvider } from 'posthog-js/react'


import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory({ window });

const POSTHOG_KEY = import.meta.env.VITE_POSTHOG_KEY;
const POSTHOG_HOST = import.meta.env.VITE_POSTHOG_HOST

const options = {
  api_host: POSTHOG_HOST
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  // <React.StrictMode>
  <PostHogProvider
    apiKey={POSTHOG_KEY}
    options={options}
  >
    <HelmetProvider>
      <HistoryRouter history={history}>
        <App />
      </HistoryRouter>
    </HelmetProvider>
  </PostHogProvider>

  // </React.StrictMode>,
);
