import { ProfileReview } from "../../components/profile-reviewer/ProfileReviewV2";
import { Wizard } from "../../components/wizard/Wizard";
import { PROFILE_REVIEWER_WIZARD_STEPS } from "../../constants/wizard";
import { useWizardStore } from "../../stores/wizard";
import { Helmet } from 'react-helmet-async';
import { ProfileReviewLanding } from './ProfileReviewLanding'
import { useLogEvent } from "../../analytics";

export const ProfileReviewer = () => {
  const {
    profileReviewerStep,
    setProfileReviewerStep,
    profileReviewerStepResults,
    setProfileReviewerStepResult,
    profileReviewerWizardComplete,
    setProfileReviewerWizardComplete,
    profileReviewStarted,
    setProfileReviewStarted,
  } = useWizardStore();

  useLogEvent('start', 'profile_review')

  return (
    <div className="px-4">
      <Helmet>
        <meta name="title" content="Dating Profile Review" />
        <meta name="description" content="Get instant feedback on your dating profile. Trained by matchmakers, powered by AI." />
      </Helmet>
      {profileReviewStarted ? (
        <div>
          <Wizard
            name="profileReviewer"
            steps={PROFILE_REVIEWER_WIZARD_STEPS}
            wizardComplete={profileReviewerWizardComplete}
            setWizardComplete={setProfileReviewerWizardComplete}
            step={profileReviewerStep}
            setStep={setProfileReviewerStep}
            stepResults={profileReviewerStepResults}
            setStepResult={setProfileReviewerStepResult}
            onBackPress={() => setProfileReviewStarted(false)}
            storeStep={true}
            showProgress={false}
          >
            <ProfileReview />
          </Wizard>
        </div>
      ) : (
        <ProfileReviewLanding onProfileReviewClick={() => setProfileReviewStarted(true)} />
      )}
    </div>
  );
};
