export const RefundPolicy = () => {
  return (
    <div className="container mx-auto max-w-3xl px-4 py-8 h-screen overflow-y-auto">
      <h1 className="text-2xl font-bold mb-6">YourMove AI Refund Policy</h1>
      
      <div className="prose">
        <p className="mb-4">
          This Refund Policy ("Policy") outlines the terms and conditions under which YourMove AI ("we," "us," or "our") processes refund requests for our services. By using our services, you acknowledge and agree to this Policy.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-3">1. General Policy</h2>
        <p className="mb-4">
          Unless explicitly stated within this Policy, our services are provided on a non-refundable basis. Any exceptions to this Policy are made solely at the discretion of YourMove AI management and are evaluated on an individual basis. Such exceptions do not constitute a waiver of this Policy for future transactions.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-3">2. AI Photo Services</h2>
        <p className="mb-4">
          AI photo generation services are considered immediately delivered and consumed upon processing. Due to the instantaneous nature of digital content delivery and the immediate allocation of computational resources, all AI photo purchases are final and non-refundable. This policy applies regardless of satisfaction with the generated results.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-3">3. 7-Day Money-Back Guarantee</h2>
        <p className="mb-4">
          For eligible subscription purchases (monthly and annual plans), we provide a 7-day money-back guarantee subject to the following terms and conditions:
        </p>
        
        <h3 className="text-lg font-semibold mt-4 mb-2">3.1 Eligibility Requirements</h3>
        <p className="mb-4">
          To qualify for a refund under our 7-day guarantee, the following conditions must be met:
        </p>
        <ul className="list-disc ml-6 mb-4">
          <li className="mb-2">The refund request must be submitted within 7 calendar days of the initial purchase date</li>
          <li className="mb-2">The account must not have exceeded any of the following usage limitations:</li>
          <ul className="list-circle ml-6 mb-4">
            <li className="mb-2">Profile Writer: Maximum of 10 profile generations</li>
            <li className="mb-2">Profile Review: Maximum of 3 profile reviews</li>
            <li className="mb-2">Photo Review: Maximum of 10 photo reviews</li>
            <li className="mb-2">Texting Assistant: Maximum of 50 message generations</li>
          </ul>
        </ul>

        <h3 className="text-lg font-semibold mt-4 mb-2">3.2 Exclusions</h3>
        <p className="mb-4">
          The following are explicitly excluded from our refund policy:
        </p>
        <ul className="list-disc ml-6 mb-4">
          <li className="mb-2">Accounts exceeding any usage limitations specified above</li>
          <li className="mb-2">Requests made after the 7-day guarantee period</li>
          <li className="mb-2">AI photo generation services</li>
          <li className="mb-2">Accounts suspended for terms of service violations</li>
        </ul>

        <h2 className="text-xl font-semibold mt-6 mb-3">4. Refund Process</h2>
        <p className="mb-4">
          To initiate a refund request, please contact our support team at support@yourmove.ai. Your request must include:
        </p>
        <ul className="list-disc ml-6 mb-4">
          <li className="mb-2">Your account email address</li>
          <li className="mb-2">Order or transaction number</li>
          <li className="mb-2">Date of purchase</li>
          <li className="mb-2">Reason for refund request</li>
        </ul>
        <p className="mb-4">
          Upon receipt of your request, our support team will review your account status and usage history to verify eligibility. Approved refunds will be processed within 5-7 business days and will be issued using the original payment method.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-3">5. Currency and Processing</h2>
        <p className="mb-4">
          All refunds will be processed in the original currency of purchase. Please note that currency exchange rates and processing fees are outside of our control, and any variations due to these factors will not be compensated.
        </p>

        <p className="text-sm text-gray-600 mt-8">
          This Refund Policy was last updated on October 24, 2024. YourMove AI reserves the right to amend or update this Policy at any time without prior notice. Any modifications to this Policy will be effective immediately upon posting on this page. Continued use of our services following any changes constitutes acceptance of the updated Policy.
        </p>
      </div>
    </div>
  );
};
