import React, { useState } from 'react';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';

type Option = {
  value: string;
  label: string;
}

type DropdownProps = {
  options: Option[];
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
}

export const Dropdown = ({
  options,
  value,
  onChange,
  placeholder,
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative">
      <div
        className={`bg-white border-2 rounded-lg py-2 px-3 flex justify-between items-center cursor-pointer ${
          isOpen ? 'border-[#BA1F33]' : 'border-black'
        }`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={value ? 'text-black' : 'text-gray-400'}>{value || placeholder}</span>
        {isOpen ? <FaChevronUp size={16} /> : <FaChevronDown size={16} />}
      </div>
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border-2 border-black rounded-lg shadow-lg">
          {options.map((option) => (
            <div
              key={option.value}
              className="p-3 hover:bg-gray-100 cursor-pointer"
              onClick={() => {
                onChange(option.value);
                setIsOpen(false);
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
