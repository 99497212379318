import { useEffect, useState } from 'react';
import { useProfileStore } from "../../stores/profile";
import { useWizardStore } from "../../stores/wizard";
import { logEvent } from "../../analytics";
import { ReviewedProfile } from "../../models/profile";
import { useUIStore } from "../../stores/ui";
import { SegmentedControlTabs } from "../../components/SegmentedControlTabs"
import { ProfileReviewUploadControl } from "../../components/profile-reviewer/ProfileReviewUploadControl"
import { AIPhotoReviewDemo } from "./AIPhotoReviewDemo";
import { ProfileReviewLoading } from "../../components/profile-reviewer/ProfileReviewLoading"
import { YourMoveHeader } from '../../components/YourMoveHeader';
import { ReviewType } from '../../models/wizard';
import { useProfileReviewData } from '../../pages/profile-review/useProfileReviewData';
import { ProfileReviewResultDemo } from './ProfileReviewResultDemo';

enum ReviewStep {
  Upload = "Upload",
  Loading = "Loading",
  Review = "Review"
}

const segments = ['✍️ Profile', '🏄‍♂️ Photos'];

export const ProfileReviewDemo = () => {
  const {
    email,
    demoReviewerFiles,
    setDemoReviewerFiles,
    setDemoWizardComplete,
    reviewType,
    setReviewType,
  } = useWizardStore();

  const {
    demoReviewedProfile,
    setDemoReviewedProfile,
  } = useProfileStore();

  const { setError, } = useUIStore()

  const getInitialStep = () => {
    if (demoReviewedProfile) return ReviewStep.Review
    return ReviewStep.Upload
  }

  const [currentStep, setCurrentStep] = useState<ReviewStep>(getInitialStep)
  const [loadingReview, setLoadingReview] = useState(false)

  const { fetchReview } = useProfileReviewData()

  const fetchProfileReview = async () => {
    try {
      logEvent('review_ai_start', 'profile_review')
      setLoadingReview(true)

      const data = await fetchReview(email, demoReviewerFiles)
      setDemoReviewedProfile(data as ReviewedProfile);
      setLoadingReview(false)
    }
    catch (error) {
      console.log("ERROR:: ", error)
      setError(
        "There was an error reviewing your profile, please try again later."
      );
      const params = (error as Error)?.message ? {
        reason: (error as Error)?.message
      } : undefined

      logEvent('review_ai_fail', 'profile_review', params, 'error')
    };
  }

  const handleSegmentChange = (index: number) => {
    const reviewType = index === 0 ? ReviewType.Profile : ReviewType.Photos;
    setReviewType(reviewType);
  };

  const onBack = () => {
    if (reviewType === ReviewType.Profile && currentStep !== ReviewStep.Upload) {
      setCurrentStep(ReviewStep.Upload)
    } else {
      setDemoWizardComplete(false)
    }
  }

  const onContinueClick = () => {
    if (reviewedProfile) {
      setCurrentStep(ReviewStep.Review)
    } else {
      setCurrentStep(ReviewStep.Loading)
      fetchProfileReview()
    }
  }

  const onLoadingComplete = () => {
    setCurrentStep(ReviewStep.Review)
  }

  return (
    <div className="mx-auto max-w-xl flex flex-col h-full">
      <YourMoveHeader containerClass='-mx-4 mb-2 mt-1' onBack={onBack} />
      <SegmentedControlTabs
        initialIndex={reviewType === ReviewType.Profile ? 0 : 1}
        segments={segments}
        onSegmentChange={handleSegmentChange}
      />
      {reviewType === ReviewType.Profile ? (
        <div className="flex-grow">
          {currentStep === ReviewStep.Upload ? (
            <ProfileReviewUploadControl onContinueClick={onContinueClick} files={demoReviewerFiles} setFiles={setDemoReviewerFiles} />
          ) : currentStep === ReviewStep.Loading ? (
            <ProfileReviewLoading complete={!loadingReview} onLoadingComplete={onLoadingComplete} />
          ) : currentStep === ReviewStep.Review && demoReviewedProfile ? (
            <ProfileReviewResultDemo onReviewAnotherProfileClick={onBack} />
          ) : null
          }
        </div>
      ) : (
        <div className="-mx-4">
          <AIPhotoReviewDemo />
        </div>
      )}
    </div>
  );
};