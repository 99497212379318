import { useEffect, useRef, useState } from "react";
import { AIPhotosBatches } from "./AIPhotosBatches";
import { useAIPhotosResults } from "./useAIPhotosResults";
import { AIPhotosBatchDetail } from "./AIPhotosBatchDetail";
import { AIPhotosWizardStep, useAIPhotosStore } from "../../stores/aiphotos";
import { getScrollableParent } from './utils';

export const AIPhotosResults = () => {
  const [selectedBatch, setSelectedBatch] = useState<number | undefined>(undefined);
  const { setAIPhotosWizardComplete, setAIPhotosWizardStep } = useAIPhotosStore()

  const { loadingBatches, fetchAIPhotoBatches, batches } = useAIPhotosResults()

  useEffect(() => {
    fetchAIPhotoBatches()
  }, [])

  const containerRef = useRef<HTMLDivElement>(null);

  const onBatchClick = (id: number) => {
    setSelectedBatch(id);
    if (containerRef.current) {
      const scrollableParent = getScrollableParent(containerRef.current);
      if (scrollableParent) {
        scrollableParent.scrollTo({
          top: 0,
          behavior: 'instant'
        });
      }
    }
  };
  const handleBackClick = () => {
    setSelectedBatch(undefined);
  };

  const handleGenerateNewBatch = () => {
    setAIPhotosWizardStep(AIPhotosWizardStep.UPLOAD_IMAGES)
    setAIPhotosWizardComplete(false)
  };

  return (
    <div ref={containerRef} className="flex flex-col h-full max-w-lg mx-auto px-4 md:px-0">
      {selectedBatch !== undefined ? (
        <AIPhotosBatchDetail
          imageUrls={batches[selectedBatch]?.outputUrls || []}
          onBackClick={handleBackClick}
        />
      ) : (
        <>
          <div className="w-full my-4">
            <button
              onClick={handleGenerateNewBatch}
              className="flex items-center justify-center w-full bg-brand-primary text-white py-3 rounded-md font-semibold"
            >
              Request another set
            </button>
          </div>
          <AIPhotosBatches
            loading={loadingBatches}
            batches={batches}
            onBatchClick={onBatchClick}
          />
        </>
      )}
    </div>
  );
};