import ReviewIllustration from '../../assets/images/review-illustration.webp'
import { useEffect, useState } from 'react'
import { FamewallReviews } from "../FamewallReviews"
import { PrimaryButton } from '../../components/PrimaryButton';

const getRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

type Props = {
  onGetStartedClick?: VoidFunction
}

export const WelcomeProfileReviewLanding = ({ onGetStartedClick = undefined }: Props) => {
  const getInitialCount = () => parseInt(localStorage.getItem("reviewsGenerated") ?? "0") || getRandomNumber(240, 300)
  const [profileReviewsCount, setProfileReviewsCount] = useState(getInitialCount);

  useEffect(() => {
    if (profileReviewsCount)
      localStorage.setItem("reviewsGenerated", profileReviewsCount.toString())
  }, [profileReviewsCount])

  useEffect(() => {
    const interval = setInterval(() => {
      setProfileReviewsCount((prevCount) => {
        const shouldStaySame = Math.random() < 0.3; // 30% chance to stay the same
        if (shouldStaySame) {
          return prevCount;
        }
        const newNumber = Math.min(prevCount + getRandomNumber(0, 5), 300);
        return newNumber;
      });
    }, 5000); // Update every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="mx-auto max-w-xl pb-4 min-h-screen flex flex-col justify-center">
        <div className="flex flex-col">
          <img src={ReviewIllustration} alt="profile" className="w-auto mx-auto h-auto" />
          <h1 className="text-2xl font-bold mt-8 text-center px-6">Your profile is your dating resume</h1>
          <p className="text-lg mt-4 text-center px-6">Optimize your profile with the #1 AI dating assistant and start getting 2x the matches overnight</p>
          <PrimaryButton
            containerClass="mt-4 mx-2"
            title="Get started"
            onClick={onGetStartedClick}
          />
          <span className='block text-center mt-4 text-sm font-normal'>
            <span className='text-brand-primary text-base font-semibold'>
              {`${profileReviewsCount} profiles`}
            </span> reviewed in the last 24h
          </span>
          <p className="text-[10px] text-center mt-4 px-6 text-gray-500">
            By continuing I agree with the <a href="https://www.yourmove.ai/terms" className="text-gray-500">Terms of Service</a> and <a href="https://www.yourmove.ai/privacy" className="text-gray-500">Privacy Policy</a>.
          </p>
        </div>
      </div>

      <FamewallReviews
        containerStyle='-mx-4 px-4'
        title='See what our users say'
      />
    </>
  )
}