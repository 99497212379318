export const ETHNICITY_OPTIONS = [
  { value: "White", label: "White" },
  { value: "Hispanic", label: "Hispanic" },
  { value: "Black", label: "Black or African American" },
  { value: "EastAsian", label: "East Asian / Pacific Islander" },
  { value: "SouthAsian", label: "South Asian" },
  { value: "Other", label: "Other" },
];

export const AGE_OPTIONS = [
  // { value: "Under 18", label: "Under 18" },
  { value: "18-24", label: "18-24" },
  { value: "25-34", label: "25-34" },
  { value: "35-44", label: "35-44" },
  { value: "45-54", label: "45-54" },
  { value: "55-64", label: "55-64" },
  { value: "65+", label: "65+" },
];

export const EYE_COLOR_OPTIONS = [
  { value: "Blue", label: "Blue" },
  { value: "Brown", label: "Brown" },
  { value: "Green", label: "Green" },
  { value: "Hazel", label: "Hazel" },
  { value: "Gray", label: "Gray" },
  { value: "Amber", label: "Amber" },
];