import { usePostHog, useFeatureFlagVariantKey } from "posthog-js/react";
import { SubscriptionPlan, SubscriptionPlanHStyle } from "./SubscriptionPlan";
import {
  MONTHLY_PRICE,
  ANNUAL_MONTHLY_PRICE,
  ANNUAL_PRICE,
  MONTHLY_PRICE_PREMIUM,
  ANNUAL_MONTHLY_PRICE_PREMIUM,
  ANNUAL_PRICE_PREMIUM,
} from "./../../../pages/premium/paymentsConfig";
import { usePaymentStore } from "../../../stores/payment";


type Props = {
  onMonthlyPress: VoidFunction;
  onAnnualPress: VoidFunction;
};


export const PremiumProducts = ({ onMonthlyPress, onAnnualPress }: Props) => {
  const postHog = usePostHog();
  const hingeStylePaywall = useFeatureFlagVariantKey('hinge-style-paywall')
  const { selectedPlan, setSelectedPlan } = usePaymentStore(); // Defaulted to annual in the state
  const showPremiumPrices = postHog?.getFeatureFlag('premium-prices') === 'test'
  const additionalDescription = showPremiumPrices ? "Save 61%" : "Save 64%"

  const monthlyPrice =
    showPremiumPrices
      ? MONTHLY_PRICE_PREMIUM
      : MONTHLY_PRICE;

  const annualPrice =
    showPremiumPrices
      ? ANNUAL_PRICE_PREMIUM
      : ANNUAL_PRICE;

  const monthlyAnnualPrice =
    showPremiumPrices
      ? ANNUAL_MONTHLY_PRICE_PREMIUM
      : ANNUAL_MONTHLY_PRICE;


  const handlePlanClick = (plan: "annual" | "monthly") => {
    setSelectedPlan(plan);

    // Only trigger immediate payment in control variant
    if (hingeStylePaywall !== 'test') {
      if (plan === "annual") {
        onAnnualPress();
      } else {
        onMonthlyPress();
      }
    }
  };

  const handleGetStarted = () => {
    if (selectedPlan === 'annual') {
      onAnnualPress();
    } else {
      onMonthlyPress();
    }
  };


  if (hingeStylePaywall === 'test') {
    const selectedTitle = selectedPlan === 'annual' ? '1 year' : '1 month';
    const selectedPrice = selectedPlan === 'annual'
      ? annualPrice  // Use full annual price
      : monthlyPrice;

    return (
      <div className="flex flex-col p-4">
        <div className="flex space-x-2">
          <div
            className="flex-1 cursor-pointer"
            onClick={() => handlePlanClick('annual')}
          >
            <SubscriptionPlanHStyle
              title="1 year"
              price={monthlyAnnualPrice}
              popular
              topLabel={additionalDescription}
              fullPrice={monthlyPrice}
              isSelected={selectedPlan === 'annual'}
            />
          </div>
          <div
            className="flex-1 cursor-pointer"
            onClick={() => handlePlanClick('monthly')}
          >
            <SubscriptionPlanHStyle
              title="1 month"
              price={monthlyPrice}
              topLabel="Flexible"
              isSelected={selectedPlan === 'monthly'}
            />
          </div>
        </div>
        <button
          className="w-full py-3 bg-[#410B13] text-white rounded-lg shadow-lg mt-4 mb-4 font-medium text-base"
          onClick={handleGetStarted}
        >
          Get {selectedTitle} for ${selectedPrice}
        </button>
      </div>
    );
  }

  return (
    <div className="flex flex-col p-4">
      <div className="flex space-x-2">
        <div
          className="flex-1 cursor-pointer"
          onClick={() => handlePlanClick('annual')}
        >
          <SubscriptionPlan
            title="Annual"
            price={monthlyAnnualPrice}
            fullPrice={monthlyPrice}  // Pass the monthly price as the full price
            additionalDescription={additionalDescription}
            isSelected={selectedPlan === 'annual'}
          />
        </div>
        <div
          className="flex-1 cursor-pointer"
          onClick={() => handlePlanClick('monthly')}
        >
          <SubscriptionPlan
            title="Monthly"
            price={monthlyPrice}
            isSelected={selectedPlan === 'monthly'}
          />
        </div>
      </div>
      {/* <p className="text-xs text-white font-ultralight text-center mt-2">
        7-day money back guarantee
      </p> */}
      <button
        className="w-full py-3 bg-[#410B13] text-white rounded-lg shadow-lg mt-4 mb-4 font-medium text-base"
        onClick={handleGetStarted}
      >
        Get Started
      </button>
    </div>
  );
};
