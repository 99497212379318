import { useState } from 'react';

interface Props {
  initialIndex?: number;
  segments: string[];
  onSegmentChange: (index: number) => void;
}

export const SegmentedControlTabs = ({
  initialIndex = 0,
  segments,
  onSegmentChange,
}: Props) => {
  const [selectedSegment, setSelectedSegment] = useState<number>(initialIndex || 0);

  const handleSegmentClick = (index: number) => {
    setSelectedSegment(index);
    onSegmentChange(index);
  };

  return (
    <div className="flex justify-center w-full">
      <div className="inline-flex bg-gray-200 rounded-full p-1 w-full">
        {segments.map((segment, index) => (
          <button
            key={segment}
            className={`flex-grow flex-basis-0 px-4 py-2 rounded-full text-sm font-medium transition-colors duration-200 ease-in-out 
              ${selectedSegment === index
                ? 'bg-white text-black'
                : 'text-gray-400 hover:bg-gray-300'
              }`}
            onClick={() => handleSegmentClick(index)}
          >
            {segment}
          </button>
        ))}
      </div>
    </div>
  );
};