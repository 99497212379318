type Props = {
  className?: string;
}
const LockIcon = ({ className }: Props) => (
  <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg" className={`fill-current stroke-current ${className}`}>
    <path d="M4.5 6.41667H2.25C1.85158 6.41667 1.46985 6.57613 1.18869 6.85937C0.90759 7.14255 0.75 7.52624 0.75 7.92593V16.7407C0.75 17.1404 0.90759 17.5241 1.18869 17.8073C1.46985 18.0905 1.85158 18.25 2.25 18.25H14.75C15.1484 18.25 15.5302 18.0905 15.8113 17.8073C16.0924 17.5241 16.25 17.1404 16.25 16.7407V7.92593C16.25 7.52624 16.0924 7.14255 15.8113 6.85937C15.5302 6.57613 15.1484 6.41667 14.75 6.41667H6.25V4.77778C6.25 4.17554 6.4875 3.59833 6.90966 3.17304C7.33176 2.74781 7.90386 2.50926 8.5 2.50926C9.58624 2.50926 10.5605 3.29541 10.7674 4.32264L10.7674 4.32265L10.7679 4.32504C10.8158 4.55124 10.9504 4.74979 11.1433 4.87717C11.3362 5.0046 11.5715 5.05031 11.798 5.00403C12.0245 4.95777 12.2231 4.82343 12.3512 4.6309C12.4792 4.43844 12.5264 4.20315 12.4831 3.97609L12.4831 3.97608L12.4826 3.97361C12.1054 2.10251 10.429 0.75 8.5 0.75L8.49974 0.75C7.43868 0.751112 6.42178 1.17625 5.67206 1.93151C4.92241 2.68672 4.5011 3.71029 4.5 4.77752V4.77778V6.41667ZM2.5 16.4907V8.17593H14.5V16.4907H2.5Z" strokeWidth="0.5" />
  </svg>
);

export default LockIcon;
