import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useAIPhotosStore } from "../../stores/aiphotos";

export const useAIPhotosUpload = (onFilesUploaded?: VoidFunction, overwrite = false) => {
  const { setFilesToUpload, filesToUpload } = useAIPhotosStore();
  const [uploading, setUploading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setUploading(true);
      try {
        const updatedFiles = overwrite ? acceptedFiles : [...filesToUpload, ...acceptedFiles];
        setFilesToUpload(updatedFiles);
        onFilesUploaded?.();
      } catch (error) {
        console.error("Error uploading files:", error);
      } finally {
        setUploading(false);
      }
    },
    [setFilesToUpload, onFilesUploaded]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    maxFiles: 100,
    disabled: uploading,
  });

  return { getRootProps, getInputProps, uploading };
}