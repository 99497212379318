export enum PlanType {
  Monthly = "monthly",
  Yearly = "yearly",
}

export enum ProductType {
  ProfileWriter = "profile_writer",
  ProfileReview = "profile_review",
  AIPhotos = "ai_photos",
}
export enum AIPhotosPlan {
  Basic = "basic",
  Plus = "plus",
  Premium = "premium",
}

