import { generateProfileReview } from "../../queries";

export const useProfileReviewData = () => {
  const fetchReview = async (email: string, profileReviewerFiles: string[]) => {
    try {
      const { data: reviewData } = await generateProfileReview(email, profileReviewerFiles)
      return reviewData
    }
    catch (error) {
      throw error
    };
  }

  return {
    fetchReview
  }
}