import { useState, useCallback } from "react";
import { supabase } from "../../supabase";

export const useSupabaseImageUpload = () => {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const uploadImagesToSupabase = useCallback(
    async (files: File[], bucketName: string, title: string) => {
      setUploading(true);
      setError(null);

      const uploadedUrls: string[] = [];

      try {
        const { error: bucketError } = await supabase.storage.createBucket(
          bucketName,
          { public: true }
        );
        if (
          bucketError &&
          bucketError.message !== "The resource already exists"
        ) {
          console.error("Error creating bucket:", bucketError.message);
          setError(`Bucket creation failed: ${bucketError.message}`);
          setUploading(false);
          return [];
        }
      } catch (error) {
        console.error("Error creating bucket:", error);
        setError("Bucket creation error");
        setUploading(false);
        return [];
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileName = file.name.toLowerCase().replace(/\s+/g, "");
        const filePath = `train/${title}/${fileName}`;

        try {
          const { data: uploadedImages, error: uploadError } = await supabase.storage
            .from(bucketName)
            .upload(filePath, file, { upsert: true });

          if (uploadError) {
            setError(`Failed to upload ${file.name}: ${uploadError.message}`);
            console.error("Supabase upload error:", uploadError.message);
            alert(uploadError.message)
            continue;
          }

          const { data } = supabase.storage
            .from(bucketName)
            .getPublicUrl(filePath);

          if (data && data.publicUrl) {
            uploadedUrls.push(data.publicUrl);
          } else {
            console.error("Error retrieving public URL for file:", filePath);
          }
        } catch (error) {
          console.error("Unexpected error during file upload:", error);
          setError(`Unexpected error uploading ${file.name}.`);
        }
      }

      setUploading(false);
      return uploadedUrls;
    },
    []
  );

  return {
    uploading,
    error,
    uploadImagesToSupabase,
  };
};
