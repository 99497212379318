import { useEffect, useRef, useState } from 'react';
import { UserInformationStep } from "./UserInformationStep";
import { AIPhotosWizardStep, useAIPhotosStore } from "../../stores/aiphotos";
import { Back } from "../../components/Back";
import { UploadPhotosStep } from "./UploadPhotosStep";
import { PreviewPhotosStep } from "./PreviewPhotosStep";
import { getScrollableParent } from './utils';
import { AIPhotosPlan } from '../../constants/payments';
import { UserEmailStep } from './UserEmailStep';
import { Loading } from '../../components/Loading';

type Props = {
  onBack: () => void;
  onWizardComplete: (selectedPlan: AIPhotosPlan) => void;
}

export const AIPhotosWizard = ({ onBack, onWizardComplete }: Props) => {
  const { aiPhotosWizardStep, setAIPhotosWizardStep } = useAIPhotosStore();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (containerRef.current) {
      const scrollableParent = getScrollableParent(containerRef.current);
      if (scrollableParent) {
        const elementRect = containerRef.current.getBoundingClientRect();
        const parentRect = scrollableParent.getBoundingClientRect();
        const scrollTop = elementRect.top - parentRect.top + scrollableParent.scrollTop - 100; // Adjust 100 as needed

        scrollableParent.scrollTo({
          top: scrollTop,
          behavior: 'instant'
        });
      }
    }
  }, [aiPhotosWizardStep]);

  const handleAIPhotosEmail = () => {
    handleContinue();
  }

  const handleContinue = async () => {
    const nextStep = aiPhotosWizardStep + 1;
    
    if (aiPhotosWizardStep === AIPhotosWizardStep.UPLOAD_IMAGES) {
      setIsLoading(true);
      await new Promise(resolve => setTimeout(resolve, 4500));
      setIsLoading(false);
    }
    
    setAIPhotosWizardStep(nextStep);
  }

  const handleWizardComplete = (selectedPlan: AIPhotosPlan) => {
    onWizardComplete(selectedPlan);
  };

  const handleBack = () => {
    if (aiPhotosWizardStep === 1) {
      onBack();
    } else {
      const prevStep = aiPhotosWizardStep - 1;
      setAIPhotosWizardStep(prevStep);
    }
  };

  return (
    <div ref={containerRef} className="max-w-lg mx-auto h-screen">
      <div className="mt-1 px-4 md:px-0 mb-6 h-full">
        <div className="flex items-center mb-4">
          <Back containerClass="w-6 h-10" color="stroke-[#999999]" onClick={handleBack} />
          <span className="ml-auto text-sm text-gray-500">Step {aiPhotosWizardStep}/{Object.keys(AIPhotosWizardStep).length / 2} </span>
        </div>
        {isLoading ? (
          <Loading titles={['Validating photos...']} />
        ) : aiPhotosWizardStep === AIPhotosWizardStep.USER_INFORMATION ? (
          <UserInformationStep onContinuePress={() => handleContinue()} />
        ) : aiPhotosWizardStep === AIPhotosWizardStep.EMAIL_INPUT ? (
          <UserEmailStep onContinuePress={handleAIPhotosEmail} />
        ) : aiPhotosWizardStep === AIPhotosWizardStep.UPLOAD_IMAGES ? (
          <UploadPhotosStep onContinue={() => handleContinue()} />
        ) : (
          <PreviewPhotosStep onContinue={handleWizardComplete} />
        )}
      </div>
    </div>
  );
};