import { ReactNode } from "react";
import * as yup from "yup";

export enum WizardStepType {
  WELCOME = "welcome",
  GENDER = "gender",
  LOOKING_FOR = "lookingFor",
  CHARACTERISTICS = "characteristics",
  ACTIVITIES = "activities",
  UNUSUAL_SKILL = "unusualSkill",
  BUCKET_LIST = "bucketList",
  TALKING_ABOUT = "talkingAbout",
  WORK_AS = "workAs",
  PROFILE_TYPE = "profileType",
  WRITING_STYLE = "writingStyle",
  EMAIL = "email",
  UPLOAD_PHOTO = "uploadPhoto",
  PAYWALL = "paywall",
  GENDER_OTHER = "GENDER_OTHER",
  SPACER = "SPACER",
  PROMO_2 = "PROMO_2",
  DEFAULT = "default",
  APPS_USED = "apps_used",
  MATCHES_CURRENT = "matches_current",
  MATCHES_QUALITY = "matches_quality",
  STATUS_CURRENT = "status_current",
  MATCHES_HAPPINESS = "matches_happiness",
  TRIED_SO_FAR = "tried_so_far",
  PROMO_3 = "promo_3",
  MATCHES_TARGET = "matches_target",
  MOTIVATION = "motivation",
  SPACER_FINAL = "SPACER_FINAL",
  REVIEW_TYPE = "reviewType"
}

export enum WizardStepInputType {
  CONTENT,
  TEXT,
  RADIO,
  RADIO_VERTICAL,
  EMAIL,
  SELECT,
  MULTI_SELECT,  // New input type for multi-select options
  FILE,
  SPACER,
}

export interface WizardStep {
  step: WizardStepType;
  type: WizardStepInputType;
  content?: ReactNode;
  label?: string;
  placeholder?: string;
  choices?: string[];
  caption?: string;

  lockedChoices?: string[];
  validator?: yup.AnySchema;
  onFilesUploaded?: (files: Array<string>) => void;
  multiple?: boolean;  // Indicates if multiple selections are allowed
  maxSelections?: number;  // Optional: limit the number of selections
}

export const enum ReviewType {
  Profile = "Profile",
  Photos = "Photos"
}