import React, { useState, useRef } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { AIPhotoDetailView } from "./AIPhotoDetailView";
import { useDownloadImages } from "./useDownloadImages";

type Props = {
  imageUrls: string[];
  onBackClick: () => void;
}

export const AIPhotosBatchDetail = ({ imageUrls, onBackClick }: Props) => {
  const [selectedImage, setSelectedImage] = useState<{ url: string; index: number } | null>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const { downloadAllImages } = useDownloadImages()

  const handleImageBackClick = () => {
    const currentScroll = scrollPosition;
    setSelectedImage(null);
    
    requestAnimationFrame(() => {
      const scrollContainer = containerRef.current?.closest('.overflow-y-auto');
      if (scrollContainer) {
        scrollContainer.scrollTop = currentScroll;
      }
    });
  };

  const handleImageClick = (imageUrl: string, index: number) => {
    const scrollContainer = containerRef.current?.closest('.overflow-y-auto');
    if (scrollContainer) {
      setScrollPosition(scrollContainer.scrollTop);
    }
    setSelectedImage({ url: imageUrl, index });
  };

  const handleNextImage = () => {
    if (selectedImage && selectedImage.index < imageUrls.length - 1) {
      setSelectedImage({
        url: imageUrls[selectedImage.index + 1],
        index: selectedImage.index + 1
      });
    }
  };

  const handlePreviousImage = () => {
    if (selectedImage && selectedImage.index > 0) {
      setSelectedImage({
        url: imageUrls[selectedImage.index - 1],
        index: selectedImage.index - 1
      });
    }
  };

  return (
    <div className="flex flex-col h-full max-w-lg">
      {selectedImage ? (
        <AIPhotoDetailView
          imageUrl={selectedImage.url}
          imageIndex={selectedImage.index}
          totalImages={imageUrls.length}
          onBackClick={handleImageBackClick}
          onNextClick={handleNextImage}
          onPreviousClick={handlePreviousImage}
        />
      ) : (
        <>
          <div className="flex justify-between items-center py-4">
            <div className="flex items-center">
              <button onClick={onBackClick} className="text-gray-400">
                <FaChevronLeft size={24} />
              </button>
              {/* <h1 className="text-lg font-semibold ml-2">{photoName}</h1> */}
            </div>
            <button
              className="text-red-500 text-sm font-semibold"
              onClick={() => downloadAllImages(imageUrls)}
            >
              Download All
            </button>
          </div>

          <div className="flex-1 p-1" ref={containerRef}>
            <div className="grid grid-cols-2 gap-4">
              {imageUrls.map((imageUrl, index) => (
                <img
                  key={index}
                  onClick={() => handleImageClick(imageUrl, index)}
                  src={imageUrl}
                  alt={`Generated Photo for ${imageUrl}`}
                  className="w-full h-full object-cover rounded-xl"
                />
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};