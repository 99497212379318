import { AIPhotosPaywall } from "../payment/paywalls/AIPhotosPaywall";
import { Modal } from "./Modal";
import { AIPhotosPlan } from "../../constants/payments";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  redirectHandler?: VoidFunction;
  selectedPlan: AIPhotosPlan;
}

const AI_PHOTOS_URL = import.meta.env.VITE_AI_PHOTOS_URL

export const UnlockAIPhotosModal = ({ open, setOpen, redirectHandler, selectedPlan }: Props) => {
  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="w-full h-full overflow-y-auto -mt-2 sm:-mt-2">
        <div className="w-full flex justify-end">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="3.5"
            stroke="currentColor"
            onClick={() => setOpen(false)}
            className="w-6 h-6 text-zinc-400 cursor-pointer"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
        {/* <div className="flex items-center justify-center">
          <h1 className="text-2xl font-semibold mb-0">Unlock AI Photos</h1>
        </div> */}
        <div className="overflow-y-scroll -mt-6 mb-6">
          <AIPhotosPaywall
            returnUrl={AI_PHOTOS_URL}
            redirectHandler={redirectHandler}
            selectedPlan={selectedPlan}
          />
        </div>
      </div>
    </Modal>
  );
};
