import { useChatStore } from "../../stores/chat";
import { useAuthStore } from "../../stores/auth";
import { useUIStore } from "../../stores/ui";
import { usePaymentStore } from "../../stores/payment";
import posthog from 'posthog-js'; // Make sure to import PostHog
import { logEventServerSide } from "../../analytics";

export const PremiumUpsellPrompt = () => {
  const { chatResponse, setChatResponse } = useChatStore();
  const { isSubscribed } = useAuthStore();
  const { hideUpsell } = useUIStore();
  const { setOpenPaymentModal, setProduct } = usePaymentStore()

  const openPaymentModal = () => {
    logEventServerSide('purchase_unlock', 'chat_assistant', {}, 'funnel')
      .catch(error => console.error('Failed to log purchase unlock:', error));
    setProduct("chat_assistant")
    setOpenPaymentModal(true)
  }

  // posthog.featureFlags.override({'replies-remaining-upsell-2': 'control'})
  // chatResponse?.queriesRemaining = chatResponse?.queriesRemaining ?? 5
  // if (!chatResponse) {
  //   setChatResponse({
  //     queriesAvailable: 10,
  //     queriesRemaining: 5,
  //     promptRisk: 0,
  //     queryDecoded: '',
  //     queryModified: '',
  //     responses: []
  //   });
  // } else {
  //   chatResponse.queriesAvailable = 10;
  //   chatResponse.queriesRemaining = 5;
  // }
  // only show if there are queries remaining, and they are less than 5 and the user is signed in

  // if (true) {
  if (!isSubscribed && chatResponse?.queriesRemaining !== undefined &&
      chatResponse?.queriesRemaining < chatResponse.queriesAvailable &&
      !hideUpsell) {
    return (
      <div className="w-full mt-4">
        <div
          onClick={openPaymentModal}
          className="cursor-pointer bg-[#270006] text-white p-4 rounded-lg relative overflow-hidden"
          style={{ height: '72px' }}
        >
          {/* Lighter semicircle */}
          <div 
            className="absolute rounded-full bg-brand-primary"
            style={{
              width: '307px',
              height: '307px',
              left: '65.5px',
              top: '142.5px',
              transform: 'translate(-50%, -50%)',
              opacity: '.5'
            }}
          />
          
          <div className="relative z-10 flex flex-col h-full justify-center">
            <div className="text-xs sm:text-sm font-semibold mb-2">
              {chatResponse.queriesRemaining} / {chatResponse.queriesAvailable} free messages remaining today
            </div>
            <div className="text-xs sm:text-sm font-bold">
              Upgrade for unlimited messages, bios, and more
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};
